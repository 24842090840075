import { Injectable } from '@angular/core';
import { AbstractControl, NgControl, NgModel } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  isControlInvalid(control: AbstractControl | NgControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }

  isNgModelInvalid(control: NgModel): boolean | null {
    return control?.invalid && (control?.dirty || control?.touched);
  }
}
