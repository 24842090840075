import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from '@dougs/user/dto';

@Pipe({
  name: 'convertMention',
  standalone: true,
})
export class ConvertMentionPipe implements PipeTransform {
  transform(value: string, usersInfo: UserInfo[]): string {
    return value.replace(/@([A-zÀ-ú-]+-)?([0-9]+)/g, (mention, name, id) => {
      const userInfo: UserInfo | undefined = usersInfo?.find((u) => u.id === parseInt(id, 10));
      if (userInfo) {
        return `<span class="mark px-4">${userInfo.fullName}</span>`;
      }
      return `<span class="mark px-4">${mention}</span>`;
    });
  }
}
