import { HttpClient } from '@angular/common/http';
import { Directive, EventEmitter, HostBinding, HostListener, inject, Output } from '@angular/core';
import { AppConfig } from '@dougs/core/config';
import { LoggerService } from '@dougs/core/logger';
import { toPromise } from '@dougs/core/utils';

@Directive({
  selector: '[dougsDragNDrop]',
  standalone: true,
})
export class DragNDropDirective {
  private readonly httpClient = inject(HttpClient);

  counter = 0;
  @Output() fileDropped: EventEmitter<FileList> = new EventEmitter<FileList>();

  constructor(private readonly logger: LoggerService) {}

  @HostBinding('class.drag-over') private isDragOver = false;

  @HostListener('dragover', ['$event']) private onDragOver(evt: DragEvent): void {
    evt.preventDefault();
  }

  @HostListener('dragenter', ['$event']) private onDragEnter(evt: DragEvent): void {
    this.counter++;
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event']) private onDragLeave(evt: DragEvent): void {
    this.counter--;
    evt.preventDefault();
    evt.stopPropagation();
    if (this.counter === 0) {
      this.isDragOver = false;
    }
  }

  @HostListener('drop', ['$event']) private async onDrop(evt: DragEvent): Promise<void> {
    evt.preventDefault();
    evt.stopPropagation();

    if (evt.dataTransfer?.getData('attachment')) {
      try {
        const attachment = JSON.parse(evt.dataTransfer.getData('attachment'));
        const list: DataTransfer = new DataTransfer();
        const blob = await toPromise(
          this.httpClient.get(`${AppConfig.settings.legacyApiServerUrl}/files/${attachment.uuid}/actions/stream`, {
            responseType: 'blob',
          }),
        );

        const file: File = new File(
          [blob],
          attachment.name,
          attachment.type
            ? {
                type: attachment.type,
              }
            : undefined,
        );
        list.items.add(file);
        this.removeDragOver();
        this.fileDropped.emit(list.files);
        return;
      } catch (e) {
        this.logger.error(e);
        this.removeDragOver();
        return;
      }
    }

    this.removeDragOver();
    const files: FileList | undefined = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  private removeDragOver(): void {
    this.counter--;
    this.isDragOver = false;
  }
}
