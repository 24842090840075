import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[dougsModalContent]',
  standalone: true,
})
export class ModalContentDirective {
  @Input() dougsModalContent?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

  @HostBinding('class')
  get elementClasses() {
    return `modal-content modal-section ${this.dougsModalContent ? this.dougsModalContent : 'medium'}`;
  }
}
